import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { styles } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const BlocksList = memo(function BlocksList({ items, title }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Wrapper>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          <Media greaterThanOrEqual="tabletMedium">
            <Items>
              {items.map((item, index) => (
                <Item key={index} {...item} />
              ))}
            </Items>
          </Media>
          <Media lessThan="tabletMedium">
            <Items>
              {items
                .sort(
                  (a: any, b: any) => a.cronologicalOrder - b.cronologicalOrder,
                )
                .map((item, index) => (
                  <Item key={index} {...item} />
                ))}
            </Items>
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;
`

const Title = styled.h2`
  ${styles.h2}
  width: 50%;
  transform: translateY(-21.875rem);

  @media (max-width: 1440px) {
    transform: translateY(-19.0625rem);
  }

  @media (max-width: 1199px) {
    transform: translateY(-11.0625rem);
  }

  @media (max-width: 992px) {
    width: 100%;
    transform: translateY(0);
    text-align: center;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`

const Items = styled.div`
  columns: 2;
  column-gap: 15.9375rem;

  @media (max-width: 1600px) {
    column-gap: 15.938vw;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    columns: unset;
  }
`
