import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  cronologicalOrder?: number
  description?: string
  image?: ImageProps
  title?: string
}

export const Item = memo(function Item({ description, image, title }: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      {image ? <StyledImage {...image} /> : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 5.9375rem;
  break-inside: avoid-column;

  @media (max-width: 1199px) {
    margin-top: 5rem;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.125rem;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }

  &:first-of-type {
    margin-top: -16.875rem;

    @media (max-width: 1440px) {
      margin-top: -14.0625rem;
    }

    @media (max-width: 1199px) {
      margin-top: -6.0625rem;
    }

    @media (max-width: 992px) {
      margin-top: 3.125rem;
    }
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  width: 100%;
  max-width: 33.125rem;
  aspect-ratio: 530/600;
  margin-top: 9.375rem;

  @media (max-width: 1440px) {
    margin-top: 5rem;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-top: 3.125rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Title = styled.h3`
  ${styles.h3}
  max-width: 27.5rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const Description = styled.div`
  ${styles.description}
  max-width: 27.5rem;
  margin-top: 1.25rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }

  @supports (initial-letter: 5) or (-webkit-initial-letter: 5) {
    &::first-letter {
      -webkit-initial-letter: unset;
      initial-letter: unset;
      padding: 0;
      margin-top: 0;
    }
  }
`
